export const ErpInvoiceType = Object.freeze({
  32: "common.rentalsReservationInvoiced",
  33: "common.rentalsReservationCancelledOwnerResponsability",
  34: "common.rentalsReservationCancelledTenantResponsability",
  36: "common.rentalsOwnerPaymentInvoiced",
  71: "common.creditNoteRentalsReservationInvoiced",
  72: "common.creditNoteRentalsReservationCancelledOwnerResponsibility",
  73: "common.creditNoteRentalsReservationCancelledTenantResponsibility",
  74: "common.creditNoteRentalsOwnerPaymentInvoiced",
  83: "common.manualInvoiceRentals",
  84: "common.creditNoteManualInvoiceRentals",
  91: "common.rentalTenantInvoice",
  95: "common.rentalSupplyManagement",
  101: "common.rentalsItpManagement",
});
